export const validHostsEmailAddresses: string[] = [
  '@buhlergroup.com',
  '@buhler-scherler.com',
  '@buhlerteh.com',
  '@premiertech.com',
  '@vyncke.com',
  '@callensvyncke.com',
  'haasmoscow.ru',
  'hanseluk.com',
  'hcbvba.be',
  'kletersteegtrading.nl',
  'medley.com.tr',
  'opessi.it',
  'peshcherin.com',
  'remaq-ca.com',
  'rodimex.com',
  'selpak.co.nz',
  'selpak.com.au',
  'sollich.co.uk',
  'timzip.com',
  'timzip.hr',
  'ufs.com.pl',
  'vitalfood.es',
  'abrishamchian.com',
  'ajb-fareast.com',
  'beaurain-ms.fr',
  'europlus.com.tw',
  'fei.com.pk',
  '@cueconcept.de',
]
