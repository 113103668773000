import styled from '@emotion/styled'
import { FeatureFlag, useFeatureFlags } from 'cuenect-web-core'
import Link from 'gatsby-link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../atoms'
import { Logo } from '../../atoms/logo'
import { LanguageChange } from '../../molecules/languageChange'
import { languages } from '../../../config/languages'
import { useGlobalContext } from '../../../utility'
import { mq } from '../../../utility/breakpoint'
import { BueClose, SieMenu, SieUser } from '../../atoms'

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = ({}) => {
  return (
    <HeaderContainer>
      <svg
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 627.5 257.4"
      >
        <style type="text/css"></style>
        <g>
          <polygon
            className="st0"
            points="283.1,129.5 285.9,109.2 276.4,109.2 273.6,129.5 	"
          />
          <path
            className="st0"
            d="M172.4,99.1L539.8,99l1.8-13.2H133.9c-27.4,0-48.1,19-48.1,42.4s20.7,42.4,48.1,42.4h26.3l1.4-10.2
   c-15-2.1-26.2-18-23.9-34.2C139.9,110.8,154,99.1,172.4,99.1z"
          />
          <path
            className="st0"
            d="M520.4,109.2h-27.6l-8.6,61.4h15.9l2.8-20.3h5.6l10,20.3h17.8l-11.3-22c7.9-2.8,13.5-9.2,14.8-18.5
   C541.7,116.7,533.6,109.2,520.4,109.2z M524.1,130.1c-0.7,4.5-3.7,7.4-9.4,7.4h-10l2.1-14.8h10
   C522.5,122.7,524.7,125.6,524.1,130.1z"
          />
          <polygon
            className="st0"
            points="403.5,109.2 387.4,109.2 378.8,170.6 420,170.6 421.9,156.7 396.8,156.7 	"
          />
          <polygon
            className="st0"
            points="427.3,170.6 471.2,170.6 473.1,156.7 445,156.7 446.5,146.1 472.2,146.1 474.1,132.6 448.4,132.6 
   449.8,123 477.9,123 479.8,109.2 435.9,109.2 	"
          />
          <path
            className="st0"
            d="M221.1,109.2h-26.3l-8.6,61.4h28.2c12.8,0,21.2-6.1,22.8-17.2c1-6.9-2.4-12.6-8-14.5c4.8-1.6,9.1-6.4,10.1-13
   C240.8,114.9,233.9,109.2,221.1,109.2z M222,151.5c-0.5,3.5-3.4,5.9-7.9,5.9h-10.8l1.7-11.9h10.8
   C220.3,145.5,222.5,147.8,222,151.5z M223.9,127.9c-0.5,3.8-3.3,5.6-8,5.6h-9.2l1.6-11.1h9.2C222.2,122.4,224.4,124.1,223.9,127.9z
   "
          />
          <polygon
            className="st0"
            points="355,132.5 333.7,132.5 337,109.2 320.9,109.2 312.3,170.6 328.4,170.6 331.8,146.3 353.1,146.3 
   349.7,170.6 365.8,170.6 374.4,109.2 358.3,109.2 	"
          />
          <path
            className="st0"
            d="M287,144.1c-1.2,8.7-5.9,13.2-12.5,13.2c-6.7,0-10.2-4.5-9-13.2l4.9-34.9h-16l-5.1,36.3
   c-2.4,16.9,6.9,26.1,23.1,26.1s28-9.2,30.4-26l5.1-36.4h-16L287,144.1z"
          />
        </g>
      </svg>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header(({ theme: { grid, colors } }) => ({
  height: '80px',
  background: colors.bgColor,
  zIndex: 2,
  width: '100vw',
  position: 'fixed',
  padding: '0 25px',
  borderBottom: `1px solid ${colors.lineDecorator}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: { width: '200px', fill: colors.primary },
  [mq[3]]: {
    justifyContent: 'flex-start',
  },
}))
