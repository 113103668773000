import { Global } from '@emotion/core'
import { Theme, ThemeProvider as CustomThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import {
  AtomsProvider,
  DebugMenu,
  LocalStorageUtil,
  MOCK_TIME,
  ThemeProvider,
  TimeUtil,
  TranslationProvider,
  useFeatureFlags,
  withDebugMenuControls,
} from 'cuenect-web-core'
import dayjs from 'dayjs'
import locale_de from 'dayjs/locale/de'
import RelativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { rgba } from 'emotion-rgba'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { I18nUtil, withTrans } from '../../../i18n'
import { useAuthentication } from '../../../utility/authentication'
import { LoadingIndicator } from '../../atoms/loadingIndicator'
import { Footer } from '../footer'
import { Header } from '../header'
import {
  AgendaCta,
  Button,
  CookieConsent,
  DraggableSlider,
  FakeFullscreen,
  LoadingSpinner,
  MobileVodVideo,
  Modal,
  SlideIn,
} from '../..'
import {
  AppointmentService,
  ProgramEvent,
  ProgramService,
} from '../../../api'
import { isPostEvent, languages } from '../../../config'
import '../../../themes/buehler/global.css'
import { buehlerTheme } from '../../../themes/buehler/buehlerTheme'
import {
  AnalyticsTransformer,
  LoadAnalytics,
  mq,
  useFullscreenContext,
  useGlobalContext,
  useModalContext,
  useReservationContext,
  useVodContext,
} from '../../../utility'

dayjs.extend(utc)
dayjs.extend(RelativeTime)

export interface InnerContainerParams {
  isTop?: boolean
}

export interface PageParams extends InnerContainerParams {
  pageContext: {
    lang: string
    item?: string
    program?: string
    hidePostEvent?: boolean
  }
  isTop?: boolean
  onScrollerInit: Function
}

export interface PageFrameProps extends PageParams {
  pageName: string
  hideMenu?: boolean
  theme?: Theme
}

export const PageFrame: React.FC<PageFrameProps> = ({
  children,
  pageContext: { lang = 'en', hidePostEvent = false },
  isTop = false,
  pageName,
  hideMenu = false,
  theme = buehlerTheme,
  onScrollerInit = null,
}) => {
  // REDIRECT TRAILING SLASHES
  if (typeof window !== 'undefined') {
    const stripTrailingSlashes = document.location.href.replace(
      /^\/+|\/+$/g,
      ''
    )
    /*  if (document.location.href !== stripTrailingSlashes) {
      navigate(stripTrailingSlashes)

      return <></>
    } */
  }
  const [loading, setLoading] = React.useState(false)
  TimeUtil.setLocale(lang === 'de' ? locale_de : 'en')

  return (
    <>
      <Helmet>
        <title>Buehler | Salesform</title>
        <meta name="description" content="" />
      </Helmet>
      <LoadAnalytics />

      <TranslationProvider
        translations={I18nUtil.flattenLocales(I18nUtil.getLocale(lang))}
      >
        <ThemeProvider theme={theme}>
          <CustomThemeProvider theme={theme}>
            <AtomsProvider
              customAtoms={{
                AgendaCta,
                Modal,
                Button,
                LoadingSpinner,
                DraggableSlider,
                FakeFullscreen,
              }}
            >
              <Header />
              {!loading && (
                <InnerContainer isTop={isTop}>{children}</InnerContainer>
              )}
            </AtomsProvider>
          </CustomThemeProvider>
        </ThemeProvider>
      </TranslationProvider>
      {/*  <Global
        styles={{
          html: {
            height: '100%',
            overflowY: 'none',
            position:
              gobalStatus?.mobileMenuOpen || isFullscreen ? 'fixed' : 'inherit',
            overscrollBehavior: 'none',

            backgroundColor: currentheme.colors.bodyBackground,
            fontFamily: currentheme.fonts.fontFamily,
            color: currentheme.colors.text,
            body: {
              borderTop:
                LocalStorageUtil.getString('mockTime') ||
                LocalStorageUtil.getString('mockTimezone')
                  ? `10px solid ${currentheme.colors.error}`
                  : 'none',
            },
            '.customTooltip': {
              background: currentheme.colors.buttonSecondaryBackground,
              maxWidth: '200px',
              zIndex: 999999,
              '&:after': {
                borderTopColor: `${currentheme.colors.buttonSecondaryBackground} !important`,
              },
            },
            '.showroomTooltip': {
              background: rgba(currentheme.colors.lightBlue, 1),
              maxWidth: '200px',
              borderRadius: 0,
              fontSize: '12px',
              zIndex: 999999,
              '&:after': {
                borderTopColor: `${currentheme.colors.lightBlue} !important`,
              },
            },
            '.debugModal > div': {
              background: currentheme.colors.bodyBackground,
              input: {
                color: '#000',
              },
            },
            '.crisp-client': {
              display: pageName === 'support' ? 'block' : 'none',
            },
          },
        }}
      ></Global> */}
    </>
  )
}
export default withTrans(PageFrame)

const InnerContainer = styled.div<InnerContainerParams>(
  ({ theme: { grid }, isTop }) => `
  padding-top:80px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
  min-height: calc(100vh - 330px);

`
)

const Loading = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:fixed;
  z-index:1003;
  width:100vw;
  height:100vh;
  left:0;top:0;
  backdrop-filter: blur(5px);
  display:flex;
  align-items:center;justify-content:center;
  background:${rgba(colors.bodyBackground, 0.5)};
`
)
const DebugBarTop = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:fixed;
  z-index:1003;
  width:100vw;
  padding:5px;
  top:0;
  display:flex;
  align-items:center;
  justify-content:center;
  background:${rgba(colors.error, 0.5)};
`
)
