import styled from '@emotion/styled'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { SalesService } from '../api'
import { mq } from '../utility/breakpoint'

import { PageContainer } from '../components/organisms/pageContainer'
import { PageContainerOuter } from '../components/organisms/pageContainerOuter'

import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import {
  SalesForm,
  SalesFormData,
  SALESFORM_ERROR,
  SALESFORM_SENT,
} from '../components/organisms/salesForm'

const SalesformPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  const [loaded, setLoaded] = React.useState(false)
  const [salesFormStatus, setSalesFormStatus] = React.useState<
    string | undefined
  >()

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  /*   React.useEffect(() => {
    const s =
      '{"emaillanguage":"en","event":"Spectre","meetinglanguage":"EN","start":"2021-08-09T10:00:00Z","end":"2021-08-09T12:00:00Z","timezone":"Europe/Berlin","gender":["Mr."],"email":["thorbenschmitt@me.com"],"firstName":["Thorben"],"lastName":["Schmitt"],"companyName":["cue"],"CRMContact":[""],"companyCountry":["Deutschland"],"hostEmail":"example@cueconcept.de","hostFirstname":"asd","hostLastname":"asd","buehlerUser":"asdasd","note":"Note","expertEmail":["example@cueconcept.de"],"expertFirstname":["12132"],"expertLastname":["123123123"],"expertStart":["2021-08-09T10:30:00Z"],"expertEnd":["2021-08-09T12:00:00Z"]}'
    SalesService.sendForm(JSON.parse(s))
  }, []) */

  const sendForm = data => {
    const tags = SalesService.getTags({ ...data })

    SalesService.sendForm(data, tags).then(({ status }) => {
      setSalesFormStatus(status !== 204 ? SALESFORM_ERROR : SALESFORM_SENT)
    })
  }

  return (
    <PageFrame {...params} hideMenu={true} pageName="index">
      <KeyVisual />

      <PageContainerOuter>
        <PageContainer>
          {loaded && (
            <SalesForm
              status={salesFormStatus}
              onReset={() => setSalesFormStatus('')}
              onSubmit={data => sendForm(data)}
            />
          )}
        </PageContainer>
      </PageContainerOuter>
    </PageFrame>
  )
}

export default SalesformPage

const KeyVisual = styled.div(() => ({
  height: '320px',
  background: `url(${
    process.env.GATSBY_PREFIX || ''
  }/assets/keyvisual.jpg) center center / cover`,
}))
